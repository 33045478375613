/*
 * Application
 */
(function($) {
    "use strict";

    jQuery(document).ready(function($) {
        /*-------------------------------
        WINTER CMS FLASH MESSAGE HANDLING
        ---------------------------------*/
        $(document).on('ajaxSetup', function(event, context) {
            // Enable AJAX handling of Flash messages on all AJAX requests
            context.options.flash = true;

            // Enable the StripeLoadIndicator on all AJAX requests
            context.options.loading = $.oc.stripeLoadIndicator;

            // Handle Flash Messages
            context.options.handleFlashMessage = function(message, type) {
                $.oc.flashMsg({ text: message, class: type });
            };

            // Handle Error Messages
            context.options.handleErrorMessage = function(message) {
                $.oc.flashMsg({ text: message, class: 'error' });
            };
        });
    });
}(jQuery));

if (typeof(gtag) !== 'undefined' && typeof(gtag) !== 'function') {
    gtag = function() { console.log('GoogleAnalytics not present.'); }
}
// Navbar
window.onscroll = function() {
    const navbar = document.getElementById('navbar');
    const lines = document.querySelectorAll('.line');
    const logo = document.getElementById('logo')
    const mobileLogoSrc = logo.dataset.mobileLogo; 
    const whiteLogoSrc = logo.dataset.whiteLogo; 



    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        navbar.classList.remove('bg-transparent');
        navbar.classList.add('bg-[#F8FBFD]', 'shadow');
        // Ubah warna garis
        lines.forEach(line => {
            line.classList.remove('bg-white'); // Hapus warna putih
            line.classList.add('bg-black'); // Tambahkan warna hitam
        });
        if (window.innerWidth < 1080) { // Mengecek apakah tampilan mobile
          logo.src = mobileLogoSrc; // Ganti dengan maca-logo
      }
    } else {
        navbar.classList.remove('bg-[#F8FBFD]', 'shadow');
        navbar.classList.add('bg-transparent');
        // Kembalikan warna garis
        lines.forEach(line => {
            line.classList.remove('bg-black'); // Hapus warna hitam
            line.classList.add('bg-white'); // Tambahkan warna putih
        });
        if (window.innerWidth < 640) { // Mengecek apakah tampilan mobile
          logo.src = whiteLogoSrc; // Kembalikan ke logo putih
      }
    }
};

var swiper = new Swiper(".mySwiperHero", {
  pagination: {
    el: ".pagin-hero",
    clickable: true,
  },
  effect: "fade",
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});

var swiper = new Swiper(".mySwiperHeroMobile", {
  pagination: {
    el: ".pagin-hero-m",
    clickable: true,
  },
  effect: "fade",
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});
// Swiper homepage
var swiper = new Swiper(".mySwiperHomeRoom", {
    effect: "fade",
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
    },
    navigation: {
        nextEl: ".swiper-button-next-homeroom",
        prevEl: ".swiper-button-prev-homeroom",
      },
});

// Swiper homepagemobile
var swiper = new Swiper(".mySwiperHomeMobile", {
  effect: "fade",
  navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
  },pagination: {
    el: ".swiper-pagination",
  },
  navigation: {
      nextEl: ".swiper-button-next-mobile",
      prevEl: ".swiper-button-prev-mobile",
    },
});

// Swiper offers homepage
var swiper = new Swiper(".mySwiperOffersRoom", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
    },
    navigation: {
        nextEl: ".swiper-button-next-offersroom",
        prevEl: ".swiper-button-prev-offersroom",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
      },
});
// Swiper Offers homepage mobile
var swiper = new Swiper(".mySwiperOffersMobile", {
  effect: "fade",
  navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
  },pagination: {
    el: ".pagin-offers",
  },
  navigation: {
      nextEl: ".swiper-button-next-ofmobile",
      prevEl: ".swiper-button-prev-ofmobile",
    },
});
// Swiper Facilities homepage mobile
var swiper = new Swiper(".mySwiperFM", {
  slidesPerView: "1.3",
  spaceBetween: 16,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});
// Swiper Facilities Page & Restaurant
var swiper = new Swiper(".mySwiperFacilitiesPage", {
    spaceBetween: 30,
    effect: "fade",
    centeredSlides: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    speed:2000,
});
// Swiper Special-Offers
var swiper = new Swiper(".mySwiperSpecialOffers", {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
    },
    speed:4000,
    allowTouchMove:false,
});
// Swiper Room Details
var swiper = new Swiper(".mySwiperRoomDetails", {
    navigation: {
      nextEl: ".swiper-button-next-detailsRoom",
      prevEl: ".swiper-button-prev-detailsRoom",
    },breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 1.4,
          spaceBetween: 50,
        },
      },
  });

const toggleButtons = document.querySelectorAll('.toggleButton');
const extraContents = document.querySelectorAll('.extraContent');
const buttonTexts = document.querySelectorAll('.buttonText');
const iconPaths = document.querySelectorAll('.iconPath');
// SVG untuk "View More" dan "View Less"
const viewMoreIcon = 'M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z';
const viewLessIcon = 'M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z';
// Tambahkan event listener ke setiap tombol
toggleButtons.forEach((button, index) => {
    button.addEventListener('click', function() {
        const extraContent = extraContents[index];
        const buttonText = buttonTexts[index];
        const iconPath = iconPaths[index];

        if (extraContent.style.maxHeight) {
            extraContent.style.maxHeight = null;
            buttonText.textContent = 'View More';
            iconPath.setAttribute('d', viewMoreIcon);
        } else {
            extraContent.style.maxHeight = extraContent.scrollHeight + 'px';
            buttonText.textContent = 'View Less';
            iconPath.setAttribute('d', viewLessIcon);
        }
    });
});
Fancybox.bind("[data-fancybox]", {
  Toolbar: {
      display: ["close"],  // Tampilkan tombol close
  },
  trapFocus: false,  // Jangan menjebak fokus di modal
  closeButton: "inside",  // Tampilkan tombol close di dalam
  click: "close",  // Klik di luar untuk menutup
  keyboard: {
      Escape: "close",  // Tekan Esc untuk menutup Fancybox
  },
});
document.addEventListener("DOMContentLoaded", function () {
  const footer = document.querySelector('.lazy-footer');
  const bgUrl = footer.getAttribute('data-bg-url'); // Ambil URL dari data attribute

  // IntersectionObserver untuk lazy loading
  const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
          if (entry.isIntersecting) {
              footer.style.backgroundImage = `url(${bgUrl})`; // Gunakan URL dari data attribute
              footer.style.backgroundPosition = "center";
              footer.style.backgroundSize = "cover";
              observer.unobserve(footer); // Berhenti mengamati setelah memuat
          }
      });
  });

  observer.observe(footer);
});

AOS.init({
  once: true,
  duration: 900
});

var swiper = new Swiper(".mySwiperAwards", {
  slidesPerView: "2",
  spaceBetween: 10,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 50,
    },
  },
});

var swiper = new Swiper(".mySwiperHeaderOffer", {
  slidesPerView: 1,
  spaceBetween: 10,
  navigation: {
    nextEl: ".pagin-header-next",
    prevEl: ".pagin-header-prev",
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2.5,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 2.2,
      spaceBetween: 20,
    },
  },
});